import { get, post, put, del } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getWineLabels: async (params) => {
    const options = { url: apiEndpoints.wineLabels, params: { ...params } };
    return get(options);
  },
  getWineLabel: async (id) => {
    const options = { url: apiEndpoints.wineLabel.replace(':id', id) };
    return get(options);
  },
  createWineLabel: async (data) => {
    const options = { url: apiEndpoints.wineLabels, data };
    return post(options);
  },
  updateWineLabel: async (data, id) => {
    const options = { url: apiEndpoints.wineLabel.replace(':id', id), data };
    return put(options);
  },
  publishWineLabel: async (data) => {
    const options = { url: apiEndpoints.wineLabelPublish, data };
    return post(options);
  },
  deleteWineLabel: async (id) => {
    const options = { url: apiEndpoints.wineLabel.replace(':id', id) };
    return del(options);
  },
  deleteWineLabelMany: async (data) => {
    const options = { url: apiEndpoints.wineLabels, data };
    return del(options);
  },
  getQRCode: async (id) => {
    const options = { url: apiEndpoints.wineLabelQRCode.replace(':id', id) };
    return get(options);
  },
  updateQRCode: async (data, id) => {
    const options = { url: apiEndpoints.wineLabelQRCode.replace(':id', id), data };
    return put(options);
  },
  wineLabelSetInMarket: async (data) => {
    const options = { url: apiEndpoints.wineLabelSetInMarket, data };
    return post(options);
  },
  cloneLabel: async (data, id) => {
    const options = { url: apiEndpoints.cloneLabel.replace(':id', id), data };
    return post(options);
  },
}
