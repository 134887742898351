import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import domainData from 'store/domainStore';
import DateRangeFilter from "./DateRangeFilter";
import EventFilter from "./EventFilter";
import KPISection from "./content/KPISection";
import PieChartSection from "./content/PieChartSection";
import DataTableSection from "./content/DataTableSection";



const DashboardLayout = observer(({ dashboardStore }) => {
  const [menuLoading, setMenuLoaded] = useState(false);
  /* to do check if Super admin
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  */

  const fetchDashboardData = async () => {
    /* to do get Partner list for Super admin
    const spNames = domainData.getSPListNames();
    */

    setMenuLoaded(true);
    dashboardStore.fetchKPIData();
    dashboardStore.fetchVisitsByWineColors();
    dashboardStore.fetchViewsByWineSweetness();
    dashboardStore.fetchViewsByWineStyle();
    dashboardStore.fetchViewsByCountry();
    dashboardStore.fetchAverageRatingByWine();
  }

  const feachInitialData = async () => {
    await dashboardStore.fechInitialData();
    await fetchDashboardData();
  }

  useEffect(() => {
    // Fetch initial data
    feachInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateRangeChange = (range) => {
    dashboardStore.setDateRange(range);
    fetchDashboardData(); // Refresh data based on the selected date range
  };

  const onMenuChange = (menu) => {
    dashboardStore.setSelectedMenu(menu);
    fetchDashboardData(); // Refresh data based on the selected event
  }

  return (
    <Grid>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        {/* Title on the left */}
        <Grid>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Dashboard</Typography>
        </Grid>

        {/* DateRangeFilter on the right */}
        <Grid container>
          <Grid>
            { /* to do add partner filter for Super admin */}
          </Grid>
          <Grid>
            { menuLoading && <EventFilter onMenuChange={onMenuChange} /> }
          </Grid>
          <Grid>
            <DateRangeFilter onDateRangeChange={onDateRangeChange} />
          </Grid>
        </Grid>
      </Grid>
      <KPISection />
      <PieChartSection />
      <DataTableSection />
    </Grid>
  );
});

export default DashboardLayout;
