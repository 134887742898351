import React, { useCallback, useMemo, useState } from 'react';
import { Popover, MenuItem, Button, Typography, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const predefinedRanges = [
  { label: 'Today', value: 'today', startDate: dayjs(), endDate: dayjs() },
  { label: 'Yesterday', value: 'yesterday', startDate: dayjs().subtract(1, 'day'), endDate: dayjs().subtract(1, 'day') },
  { label: 'Last 7 Days', value: 'last_7_days', startDate: dayjs().subtract(6, 'days'), endDate: dayjs() },
  { label: 'Last 30 Days', value: 'last_30_days', startDate: dayjs().subtract(29, 'days'), endDate: dayjs() },
  { label: 'Custom', value: 'custom' }
];

const DateRangePicker = ({ onDateRangeChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRange, setSelectedRange] = useState('last_7_days');
  const [customRangeView, setCustomRangeView] = useState(false);
  const [temporaryCustomRange, setTemporaryCustomRange] = useState({
    startDate: dayjs().subtract(6, 'days'),
    endDate: dayjs()
  });
  const [customRange, setCustomRange] = useState({
    startDate: dayjs().subtract(6, 'days'),
    endDate: dayjs()
  });

  const openPopover = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopover = useCallback(() => {
    setAnchorEl(null);
    setCustomRangeView(false);
  }, []);

  const handleRangeSelect = (range) => {
    setSelectedRange(range.value);

    if (range.value === 'custom') {
      setCustomRangeView(true);
    } else {
      setCustomRange({ startDate: range.startDate, endDate: range.endDate });
      onDateRangeChange({ start: range.startDate.format('YYYY-MM-DD'), end: range.endDate.format('YYYY-MM-DD') });
      closePopover();
    }
  };

  const handleCustomDateChange = (newDate, isStart) => {
    const parsedDate = dayjs(newDate);

    if (!parsedDate.isValid()) {
      console.error("Invalid Date selected:", newDate);
      return;
    }

    setTemporaryCustomRange((prevRange) => {
      const updatedRange = {
        ...prevRange,
        [isStart ? 'startDate' : 'endDate']: parsedDate
      };
      return updatedRange;
    });
  };

  const applyCustomRange = () => {
    setCustomRange(temporaryCustomRange);
    onDateRangeChange({
      start: temporaryCustomRange.startDate.format('YYYY-MM-DD'),
      end: temporaryCustomRange.endDate.format('YYYY-MM-DD')
    });
    closePopover();
  };

  const isApplyDisabled = useMemo(() => !temporaryCustomRange.startDate || !temporaryCustomRange.endDate || temporaryCustomRange.startDate.isAfter(temporaryCustomRange.endDate), [temporaryCustomRange]);

  const formattedRange = useMemo(() => {
    if (customRange.startDate && customRange.endDate) {
      return `${customRange.startDate.format('DD.MM.YY')} - ${customRange.endDate.format('DD.MM.YY')}`;
    }
    return 'Select a date range';
  }, [customRange]);

  return (
    <Box>
      <Button
        onClick={openPopover}
        endIcon={<ArrowDropDownIcon />}
        sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
      >
        <Typography variant="body2" sx={{ mr: 1 }}>
          {predefinedRanges.find((range) => range.value === selectedRange)?.label || 'Custom'}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {formattedRange}
        </Typography>
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box>
          {!customRangeView && predefinedRanges.map((range) => (
            <MenuItem key={range.value} onClick={() => handleRangeSelect(range)}>
              {range.label}
            </MenuItem>
          ))}
          {customRangeView && selectedRange === 'custom' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={temporaryCustomRange.startDate}
                    onChange={(date) => handleCustomDateChange(date, true)}
                    maxDate={dayjs()} // Ensure no future dates
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={temporaryCustomRange.endDate}
                    onChange={(date) => handleCustomDateChange(date, false)}
                    minDate={temporaryCustomRange.startDate} // Disable dates before startDate
                    maxDate={dayjs()} // Ensure no future dates
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                <Button variant="outlined" size="small" onClick={closePopover}>Cancel</Button>
                <Button variant="contained" size="small" onClick={applyCustomRange} disabled={isApplyDisabled}>Apply</Button>
              </Box>
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

DateRangePicker.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired
};

export default DateRangePicker;
