import { runInAction, makeAutoObservable, action, extendObservable, set } from "mobx";
import ServiceProvidersService from 'services/serviceProvidersService';
import SubscriptionService from 'services/subscriptionService';

import { isSuperAdmin, isPartner, isSuperAdminOrPartner } from "helpers/usersRolesHelper";
import lsConstants from 'constants/local-storage';

import catalogueStore from './catalogueStore';
import kiosksMenusStore from './kiosksMenusStore';

class DomainData {

  getDefaultStoreProps = () => ({
    currentUser: null,
    currentSP: null,
    SPlist: [],
    initialdataLoaded: false,
    initialdataLoading: false,
    initialdata: {},
    subscriptionAccess: {
      label: false,
      dashboard: false,
      wine: false,
      wineList: false,
    }
  })

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  isInitialDataLoaded = () => this.initialdataLoaded;

  @action
  resetInitialData = () => {
    this.currentUser = null;
    this.currentSP = null;
    this.SPlist = [];
    this.initialdataLoaded = false;
    this.initialdataLoading = false;
    this.initialdata = {};
  }

  setCurrentUser = (user) => {
    this.currentUser = user;
    localStorage.setItem(lsConstants.CURRENT_USER, JSON.stringify(user));
  }

  getCurrentUser = () => {
      if (this.currentUser) {
        return this.currentUser;
      }
      runInAction(() => {
        this.currentUser = JSON.parse(localStorage.getItem(lsConstants.CURRENT_USER));
      });
      return this.currentUser;
  };

  userIsSuperAdmin = () => {
    const curr = this.getCurrentUser();
    return isSuperAdmin(curr);
  }

  userIsPartner = () => {
    const curr = this.getCurrentUser();
    return isPartner(curr);
  }

  userIsSuperAdminOrPartner = () => {
    const curr = this.getCurrentUser();
    return isSuperAdminOrPartner(curr);
  }

  initializeData = async () => {
    if (this.initialdataLoaded) {
        return true;
    }
    if (this.initialdataLoading) {
        return new Promise(resolve => {
        const checkData =() => {
            if (this.initialdataLoaded) {
                resolve(true)
            } else {
                setTimeout(checkData, 1000)
            }
        };
        checkData();
        });
    }
    await this.loadInitialData();
    return true;
  }

  loadInitialData = async () => {
    this.initialdataLoading = true;
    try {
      this.currentUser = JSON.parse(localStorage.getItem(lsConstants.CURRENT_USER));
      if (isSuperAdmin(this.currentUser)) {
        const response = await ServiceProvidersService.getServiceProvidersNames();
        runInAction(() => {
          if (response) {
              this.SPlist = response.data.data;
          }
          this.currentSP = localStorage.getItem(lsConstants.CURRENT_SERVICE_PROVIDER);
          this.initialdataLoading = false;
          this.initialdataLoaded = true;
        });
      } else {
        this.currentSP = localStorage.getItem(lsConstants.CURRENT_SERVICE_PROVIDER);
        const response = await ServiceProvidersService.getServiceProvider(this.currentSP);
        runInAction(() => {
          if (response) {
              this.SPlist = [response.data];
          }
          this.initialdataLoading = false;
          this.initialdataLoaded = true;
        });
      }
    } catch (err) {
      this.initialdataLoading = false;
      // enqueueSnackbar('Get kiosks lists error', { variant: 'error' });
    }
  }

  getSPList = () => this.SPlist;

  // get service provider data
  getSPListNames = async() => {
    if (this.SPlist) {
      return this.SPlist;
    }
    const response = await ServiceProvidersService.getServiceProvidersNames();
    if (response) {
      this.SPlist = response.data.data;
    }
    return response.data.data;
  };

  getSPListName = async(id) => {
    if (this.SPlist) {
      return (this.SPlist.find(i => +i.id === +id));
    }
    const response = await ServiceProvidersService.getServiceProvidersNames();
    if (response) {
        this.SPlist = response.data.data;
    }
    return (response.data.data.find(i => +i.id === +id));
  };

  getCurrentSP = () => {
    if (this.currentSP) {
        return this.currentSP;
    }
    runInAction(() => {
      this.currentSP = localStorage.getItem(lsConstants.CURRENT_SERVICE_PROVIDER);
    });
    if (this.userIsPartner()) {
      catalogueStore.setServiceProviderId(this.currentSP);
      kiosksMenusStore.setServiceProviderId(this.currentSP);
    }
    return this.currentSP;
  }

  setCurrentSP = (value) => {
    this.currentSP = value;
    localStorage.setItem(lsConstants.CURRENT_SERVICE_PROVIDER, value);
    catalogueStore.setServiceProviderId(this.currentSP);
    kiosksMenusStore.setServiceProviderId(this.currentSP);
  }

  getCurrentSpData = () => this.SPlist?.find(sp => sp.id === Number(this.currentSP));

  checkSubscriptionAccess = async (isUserSuperAdmin) => {
    if (isUserSuperAdmin) {
      this.subscriptionAccess.label = true;
      this.subscriptionAccess.dashboard = true;
      this.subscriptionAccess.wine = true;
      this.subscriptionAccess.wineList = true;
    } else {
      const response = await SubscriptionService.checkSubscriptionAccess();
      if (response?.data) {
        this.subscriptionAccess = response.data;
      }
    }
  };

  getSubscriptionAccess = (page) => this.subscriptionAccess[page];

}

export default new DomainData();
