import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const WINE_COLOR_MAP = {
  red: '#FAC3C3',
  white: '#FEF9DE',
  rose: '#FFEAED',
  orange: '#FFA500'
};

const PieChartWidget = ({ title, data, wineColors = false }) => (
  <Paper elevation={2} sx={{ padding: 2, paddingBottom: 8, minHeight: 300 }}>
    <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>{title}</Typography>
    { !data.length && <Typography variant="body2">No data available</Typography> }
    <Box sx={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius="65%"
            outerRadius="100%"
            fill="#ffc658"
            startAngle={270}
            endAngle={-90}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={wineColors && WINE_COLOR_MAP[entry.name.toLowerCase()]
                  ? WINE_COLOR_MAP[entry.name.toLowerCase()]
                  : COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Box>
    
    <Grid container spacing={1} sx={{ paddingTop: 2 }}>
      {data.map((entry, index) => (
        <Grid
          size={{ xs: 4, md: 4 }}
          key={entry.name}
          sx={{ display: 'flex', alignItems: 'center', paddingLeft: 1 }}
        >
          <Box
            component="span"
            sx={{
              backgroundColor: wineColors && WINE_COLOR_MAP[entry.name.toLowerCase()]
                ? WINE_COLOR_MAP[entry.name.toLowerCase()]
                : COLORS[index % COLORS.length],
              width: 12,
              height: 12,
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: 1,
            }}
          />
          <Typography variant="body2" component="span">
            {entry.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </Paper>
);

PieChartWidget.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  wineColors: PropTypes.bool
};

export default PieChartWidget;
